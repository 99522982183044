<template>
  <a-card style="background-color: #fff;">
    <div :class="advanced ? 'search' : null" v-if="hasPermission('SFA_CUSTOMER_LIST')">
      <a-form layout="horizontal">
        <div :class="advanced ? null : 'fold'">
          <div  style="display: flex;text-align: center;margin-left: 20px;margin-bottom: 10px;" class='top-text'>
            <div>
              <p>全部客户</p>
              <p>{{ totalNumber }}</p>
            </div>
            <div>
              <p>今日新增</p>
              <p>{{ countCustomerToday }}</p>
            </div>
          </div>
          <a-row>
            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="客户名称" :labelCol="{ span: 6 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-input v-model="queryData.name" placeholder="请输入客户姓名" />
              </a-form-item>
            </a-col>
            <a-col :md="iptmd" :sm="iptsm">
              <a-form-item label="创建时间" :labelCol="{ span: 6 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-row>
                  <a-range-picker :ranges="{
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
             '当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }" @change="dateChange" />
                </a-row>
              </a-form-item>
            </a-col>
            <a-col :md="iptmd" :sm="iptsm">
              <span class="btn-box" style="margin-top: 3px; display: inline-block;">
                <a-col :md="12" :sm="20">
                  <a-button type="primary" @click="getData">查询</a-button>
                </a-col>
              </span>
            </a-col>
          </a-row>
        </div>
      </a-form>

      <a-row>
        <a-button @click="addBtn" type="primary" v-if="hasPermission('SAVE_CUSTOMER')">新增</a-button>
        <a-button @click="exportChange(1)" v-if="hasPermission('SHIFT_CUSTOMER')" style="margin-left: 8px"
          type="primary">批量转移</a-button>
      </a-row>
    </div>

    <!-- 创建 -->
    <a-modal title="新增联系人" :visible="visible" :maskClosable="false" @ok="handleOk" :footer="null"
      @cancel="visible = false; prov1 = []; prov2 = []; prov3 = []; prov4 = []; prov5 = []; prov6 = [];" :width="1100">
      <a-form-model ref="ruleForm" :model="userInfo" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">

        <div style="margin-left:40px;font-weight: 700;font-size: 20px;margin-bottom: 10px;">
          公司信息
        </div>

        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item required ref="gsname" prop="gsname" label="公司全称" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="userInfo.gsname" placeholder="请输入公司全称" @blur="() => { $refs.name.onFieldBlur(); }" />
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="公司简称" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="userInfo.abbreviation" placeholder="请输入公司简称"
                  @blur="() => { $refs.name.onFieldBlur(); }" />
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>


        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item label="公司人数" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-input v-model="userInfo.scale" placeholder="请输入公司人数" />
              <!-- <a-select v-model="userInfo.scale" style="width: 100%" placeholder="请选择公司人数">
                <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in scaleList" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select> -->
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="公司类型" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-select v-model="userInfo.enterpriseType" style="width: 100%" placeholder="请选择公司类型">
                <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in enterpriseType"
                  :key="index">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>


        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item label="来源" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-select v-model="userInfo.source" style="width: 100%" placeholder="请选择来源">
                <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in soureList" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">

          </a-col>
        </a-row>


       

        <a-form-model-item label="预算总额" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <span>¥{{ totalWelfareAmount }}</span>
        </a-form-model-item>
        <a-form-model-item required label="福利类型" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <ul style="display: flex;position: relative;list-style: none;padding:0;flex-wrap: wrap;">
            <li v-for="item in customerWelfareList" :key="item.id"
              style="display: flex;align-items: center;margin-left: 10rpx;">
              <span>{{ item.describe }}:</span> <a-input-number :min="0" style="width:100px;margin: 0 0px 0 10px;"
                v-model="item.welfareAmount" /><span style="margin: 0 10px;">元/年</span>
            </li>
          </ul>
        </a-form-model-item>

        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="24">
            <a-form-model-item label="公司地址" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;margin-bottom: 10px;" v-for="(item, i) in customerAddressList" :key="i">
                <a-select @change="provChange1($event, i)" :index="i" placeholder="请选择省">
                  <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in prov" :key="index">{{
                    item.name
                  }}</a-select-option>
                </a-select>
                <a-select @change="cityChange1($event, i)" :index="i" placeholder="请选择市">
                  <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in city" :key="index">{{
                    item.name
                  }}</a-select-option>
                </a-select>
                <a-select @change="areaChange1($event, i)" :index="i" placeholder="请选择区">
                  <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in area" :key="index">{{
                    item.name
                  }}</a-select-option>
                </a-select>
                <a-input v-model="item.detailedAddress" placeholder="详细地址" />
                <a-button v-if="i == 0" type="primary" style="margin-left: 10px;" @click="addAddress(3, i)">添加</a-button>
                <a-button v-else style="margin-left: 10px;" @click="addAddress(4, i)">删除</a-button>
              </div>
            </a-form-model-item>
          </a-col>

        </a-row>

        <div style="margin-left:40px;font-weight: 700;font-size: 20px;margin-bottom: 10px;">
          个人信息
        </div>

        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item required ref="name" prop="name" label="联系人" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="userInfo.name" placeholder="请输入联系人" />
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="联系人角色" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-select v-model="userInfo.role" style="width: 100%" placeholder="请选择联系人角色">
                <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in roleList" :key="index">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>


        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item label="客户经理" ref="customerManagerIdList" prop="customerManagerIdList"
              :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-select v-model="userInfo.customerManagerIdList" mode="multiple" style="width: 100%"
                placeholder="请选择客户经理">
                <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in users" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="性别" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-select v-model="userInfo.sex" style="width: 100%" placeholder="请选择联系人性别">
                <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in sexList" :key="index">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row class="form-row" type="flex" justify="center">
          <a-col :span="11">
            <a-form-model-item label="生日" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-date-picker style="width: 100%;" @change="birthdayChange" placeholder="请选择联系人生日" />
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="备注" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;position: relative;">
            <a-input v-model="userInfo.remarks" placeholder="请输入备注" @blur="() => { $refs.name.onFieldBlur(); }" />
          </div>
        </a-form-model-item>
          </a-col>
        </a-row>






        <a-form-model-item required label="联系人联系方式" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;" class="lx">
            <div style="position: relative;flex: 1;">
              <div v-for="(item, index) in phone" :key="index" style="display: flex;margin-bottom: 4px;">
                <a-select :disabled="index == 0" label-in-value :default-value="{ key: '手机号' }"
                  style="width: 120px; margin: 0 4px;" @change="phoneChange($event, index)">
                  <a-select-option value="1">手机号</a-select-option>
                  <a-select-option value="2">座机号</a-select-option>
                  <a-select-option value="3">邮箱</a-select-option>
                  <a-select-option value="4">微信号</a-select-option>
                  <a-select-option value="5">QQ号</a-select-option>
                  <a-select-option value="6">其他</a-select-option>
                </a-select>
                <a-form-model-item required style="width:100%;margin-top: -2px;">
                  <a-input v-if="item.type == 1" placeholder="请输入联系人手机号" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                  <a-input v-if="item.type == 2" placeholder="请输入联系人座机号" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                  <a-input v-if="item.type == 3" placeholder="请输入联系人邮箱" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                  <a-input v-if="item.type == 4" placeholder="请输入联系人微信号" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                  <a-input v-if="item.type == 5" placeholder="请输入联系人QQ号" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                  <a-input v-if="item.type == 6" placeholder="请输入其他联系方式" v-model="item.contactInformation"
                    @blur="() => { $refs.phone.onFieldBlur(); }" />
                </a-form-model-item>
                <a-button v-if="index > 0" type="error" @click="delIpt(0, index)">删除</a-button>
              </div>
            </div>
            <a-button type="primary" style="margin-left: 10px;" @click="addIpt(0)">添加</a-button>
          </div>
          <div style="display: flex;margin-top: 10px;" class="lx">
            <div style="position: relative;flex: 1;">
              <div v-for="(item, index) in wechat" :key="index" style="display: flex;margin-bottom: 4px;">
                <a-select :disabled="index == 0" label-in-value :default-value="{ key: '微信号' }"
                  style="width: 120px; margin: 0 4px;" @change="weChatChange($event, index)">
                  <a-select-option value="1">手机号</a-select-option>
                  <a-select-option value="2">座机号</a-select-option>
                  <a-select-option value="3">邮箱</a-select-option>
                  <a-select-option value="4">微信号</a-select-option>
                  <a-select-option value="5">QQ号</a-select-option>
                  <a-select-option value="6">其他</a-select-option>
                </a-select>
                <a-form-model-item required style="width:100%;margin-top: -2px;">
                  <a-input v-if="item.type == 1" placeholder="请输入联系人手机号" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                  <a-input v-if="item.type == 2" placeholder="请输入联系人座机号" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                  <a-input v-if="item.type == 3" placeholder="请输入联系人邮箱" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                  <a-input v-if="item.type == 4" placeholder="请输入联系人微信号" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                  <a-input v-if="item.type == 5" placeholder="请输入联系人QQ号" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                  <a-input v-if="item.type == 6" placeholder="请输入其他联系方式" v-model="item.contactInformation"
                    @blur="() => { $refs.wechat.onFieldBlur(); }" />
                </a-form-model-item>

                <a-button v-if="index > 0" type="error" @click="delIpt(1, index)">删除</a-button>
              </div>
            </div>
            <a-button type="primary" style="margin-left: 10px;" @click="addIpt(1)">添加</a-button>
          </div>

          <div style="display: flex;margin-top: 10px;" class="lx">
            <div style="position: relative;flex: 1;">
              <div v-for="(item, index) in zuoji" :key="index" style="display: flex;margin-bottom: 4px;">
                <a-select :disabled="index == 0" label-in-value :default-value="{ key: '座机号' }"
                  style="width: 120px; margin: 0 4px;" @change="zuojiChange($event, index)">
                  <a-select-option value="1">手机号</a-select-option>
                  <a-select-option value="2">座机号</a-select-option>
                  <a-select-option value="3">邮箱</a-select-option>
                  <a-select-option value="4">微信号</a-select-option>
                  <a-select-option value="5">QQ号</a-select-option>
                  <a-select-option value="6">其他</a-select-option>
                </a-select>

                <a-form-model-item required style="width:100%;margin-top: -2px;">
                  <a-input v-if="item.type == 1" placeholder="请输入联系人手机号" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                  <a-input v-if="item.type == 2" placeholder="请输入联系人座机号" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                  <a-input v-if="item.type == 3" placeholder="请输入联系人邮箱" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                  <a-input v-if="item.type == 4" placeholder="请输入联系人微信号" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                  <a-input v-if="item.type == 5" placeholder="请输入联系人QQ号" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                  <a-input v-if="item.type == 6" placeholder="请输入其他联系方式" v-model="item.contactInformation"
                    @blur="() => { $refs.zuoji.onFieldBlur(); }" />
                </a-form-model-item>
                <a-button v-if="index > 0" type="error" @click="delIpt(2, index)">删除</a-button>
              </div>
            </div>
            <a-button type="primary" style="margin-left: 10px;" @click="addIpt(2)">添加</a-button>
          </div>
        </a-form-model-item>


        <a-form-model-item label="联系人收货地址" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
          <div style="display: flex;margin-bottom: 10px;" v-for="(item, i) in sfaContactAddressList" :key="i">
            <a-form-model-item style="width:100%;margin-top: -2px;">
              <a-input v-model="item.consignee" placeholder="请输入收货人" />
            </a-form-model-item>
            <a-form-model-item style="width:100%;margin-top: -2px;">
              <a-input v-model="item.mobile" placeholder="收货人手机号" />
            </a-form-model-item>

            <a-form-model-item style="width:100%;margin-top: -2px;">
              <a-select @change="provChange($event, i)" :index="i" placeholder="请选择省">
                <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in prov" :key="index">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item style="width:100%;margin-top: -2px;">
              <a-select @change="cityChange($event, i)" :index="i" placeholder="请选择市">
                <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in city" :key="index">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item style="width:100%;margin-top: -2px;">
              <a-select @change="areaChange($event, i)" :index="i" placeholder="请选择区">
                <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in area" :key="index">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-input v-model="item.detailedAddress" placeholder="小区门牌号" />
            <a-button v-if="i == 0" type="primary" style="margin-left: 10px;" @click="addAddress(0, i)">添加</a-button>
            <a-button v-else style="margin-left: 10px;" @click="addAddress(1, i)">删除</a-button>
          </div>
        </a-form-model-item>






     

      </a-form-model>

      <div style="display: flex;justify-content: center;">
        <a-button type="primary" style="margin-right: 10px;" @click="handleOk">保存</a-button>
        <!-- <a-button type="primary" @click="ToDetail">立即选品</a-button> -->
      </div>
    </a-modal>

    <!-- 转移 -->
    <a-modal title="转移联系人" :visible="exportVisible" :maskClosable="false" @ok="exportHandle"
      @cancel="exportVisible = false" :width="700">
      <a-select mode="multiple" 
      :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
      v-model="queryData.customerManagerIdList" style="width: 100%" placeholder="请选择归属人">
        <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in users" :key="index">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-modal>
    <div style="margin-top: 20px;" v-if="hasPermission('SFA_CUSTOMER_LIST')">
      <a-table :columns="columns" :dataSource="dataSource" :loading="tabLoading" :pagination="false"
        :row-selection="rowSelection" :expanded-row-keys.sync="expandedRowKeys" :scroll="{ x: 1500 }">
        <template slot="createTime" slot-scope="record">
          {{ formatDate(record) }}
        </template>

        <template slot="action" slot-scope="record">
          <div style="padding-left: 12px;">
            <div style="text-align: left;margin-bottom: 2px;">
              <a @click="editChange(record.id)" v-if="hasPermission('UPDATE_CUSTOMER') && record.isBelong">编辑</a>
              <a style="margin:0 5px" @click="exportChange(0, record.id)">转移</a>
              <a v-if="hasPermission('CONTACT_LIST')" @click="details(record.id)">联系人</a>
            </div>
            <div style="text-align: left;">
              <a @click="progDeal(record.id)" v-if="hasPermission('INVOICE_LIST')" style="margin-right: 5px;">发票</a>
              <a-popconfirm style="cursor: pointer;" title="您确定要删除吗？" ok-text="是" cancel-text="否"
                @confirm="delConfirm(record)" v-if="hasPermission('DEL_CUSTOMER') && record.isBelong">删除</a-popconfirm>
            </div>
          </div>
        </template>
      </a-table>
      <div class="page-box">
        <a-pagination :total="total" :current="currentPage" :pageSize="queryData.size" @change="handlePageChange" />
      </div>
    </div>

    <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-else>
      <a-empty :description="false" />
        无权限查看
    </div>
  </a-card>
</template>

<script>
// import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { mapGetters } from 'vuex'

const columns = [
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    align: 'center',
    width: 100,
  },
  {
    title: '公司名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
    ellipsis: true,
    align: 'center',
    width: 150

  },
  {
    title: '公司人数',
    dataIndex: 'scaleName',
    align: 'center',
    ellipsis: true,
    scopedSlots: { customRender: 'scaleName' },
    width: 100
  },
  {
    title: '企业类型',
    dataIndex: 'enterpriseTypeName',
    align: 'center',
    ellipsis: true,
    scopedSlots: { customRender: 'enterpriseTypeName' },
    width: 100
  },
  {
    title: '预算总额',
    dataIndex: 'budgetAmount',
    align: 'center',
    width: 100
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    fixed: 'right',
    width: 160
  }
]

export default {
  name: 'list',
  inject: ['reload'],
  data() {
    return {
      userInfo: { budgetAmount: 0, customerManagerIdList: [] },
      // 公司人数
      scaleList: [{ id: 1, name: '50人以下' }, { id: 2, name: '50-99人' }, { id: 3, name: '100-499人' }, { id: 4, name: '500-999人' }, { id: 5, name: '1000-9999人' }, { id: 6, name: '10000人以上' },],
      // 类型
      enterpriseType: ['医院', '街道', '医院', '综合', '咨询/设备/技术公司', '科技公司', '出版社', '酒店', '工程有限公司', '银行', '事业单位', '路桥建设', '税务局', '卫生局', '审计局', '进出口贸易', '园林局', '学校', '其他'],
      // 来源
      soureList: [{name:'天眼查',id:1},{name:'企查查',id:2}, {name:'陌生拜访',id:3}, {name:'朋友介绍',id:4}, {name:'客户转介绍',id:6},  {name:'其他',id:5}],
      // 联系人列表
      sfaContactsList: [{}],
      // 收货地址列表
      sfaContactAddressList: [{
        "consignee": "",
        "mobile": "",
        "provinceCode": "",
        "provinceName": "",
        "cityCode": "",
        "cityName": "",
        "countyCode": "",
        "countyName": "",
        "detailedAddress": ""
      }],
      // 公司地址
      customerAddressList: [{
        "provinceCode": '',
        "provinceName": "",
        "cityCode": '',
        "cityName": "",
        "countyCode": '',
        "countyName": "",
        "detailedAddress": ""
      }],
      selectedValue: '',
      // 福利列表
      customerWelfareList: [
        {
          "welfareId": "2GBge756Ad",
          "welfareAmount": 0
        }, {
          "welfareId": "uMutq2tNYO",
          "welfareAmount": 0
        }, {
          "welfareId": "Euw01T1K1T",
          "welfareAmount": 0
        }, {
          "welfareId": "KfY1Ei3gQi",
          "welfareAmount": 0
        }, {
          "welfareId": "JxBVvK5vJX",
          "welfareAmount": 0
        }, {
          "welfareId": "lltjBncXD3",
          "welfareAmount": 0
        }, {
          "welfareId": "Bb1qHXRyZi",
          "welfareAmount": 0
        }
      ],
      countCustomerToday: 0,//今日新增
      totalNumber: 0,//全部
      expandedRowKeys: [],
      iptmd: 4,
      iptsm: 22,
      moment,
      customerData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      tabLoading: false,
      selectedRows: [],
      total: 0,
      // 筛选
      queryData: {
        page: 0,
        size: 10,
      },
      currentPage: 1,
      // 创建表单
      form: {
        phone: [{ value: '' }],
        customerId: [],
        type: [],
        productPrice: '',
        productBudget: '',
        businessExpenses: '',
        purchaseQuantity: '',
        expectedProcurementTime: '',
        remarks: ''
      },
      // 新建方案弹窗
      labelCol: { span: 8 },
      wrapperCol: { span: 24 },
      rules: {
        name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        gsname: [{ required: true, message: '请输入公司全称', trigger: 'blur' }],
        role: [{ required: true, message: '请选择角色', trigger: 'change' }],
        customerManagerIdList: [{ required: true, message: '请选择客户经理', trigger: 'change' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        phone: [{ required: true, message: '请输入号码', trigger: 'blur' }],
        wechat: [{ required: true, message: '请输入号码', trigger: 'blur' }],
        zuoji: [{ required: true, message: '请输入号码', trigger: 'blur' }],
        consignee: [{ required: true, message: '请输入收货人', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入收货人电话', trigger: 'blur' }],
        abbreviation: [{ required: true, message: '请输入公司简称', trigger: 'blur' }],
        // scale: [{ required: true, message: '请选择公司人数', trigger: 'change' }],
        scale: [{ required: true, message: '请选择公司人数', trigger: 'blur' }],
        enterpriseType: [{ required: true, message: '请选择公司类型', trigger: 'change' }],
        source: [{ required: true, message: '请选择来源', trigger: 'change' }],
      },
      roleList: ["未知", "总负责人", "重要决策人", "办事人员", "其他"],
      sexList: ["男", "女", "未知"],
      phone: [{ contactInformation: "", type: '1' }],
      wechat: [{ contactInformation: "", type: '4' }],
      zuoji: [{ contactInformation: "", type: '2' }],
      prov: [],
      city: [],
      area: [],
      visible: false,
      welfare: [],
      customerManagerIdList: [], //客户经理id
      users: [],

      exportVisible: false,

      selectedRowKeys: [], // Check here to configure the default column

      exportIdArr: []

    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    totalWelfareAmount() {
      return this.customerWelfareList.reduce((total, item) => {
        this.userInfo.budgetAmount = total + Number(item.welfareAmount);
        return total + Number(item.welfareAmount);
      }, 0);
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
      };
    },
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  mounted() {
    if(!this.hasPermission('SFA_CUSTOMER_LIST'))return false;

    //  客户经理 
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
      this.users = res.data.data;
      for (let i in this.users) {
        if (this.users[i].account == this.user.account) {
          this.userInfo.customerManagerIdList = [this.users[i].id]
          return false;
        }
      }
    })
  },
  methods: {
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    addBtn() {
      this.visible = true;
      this.phone = [{ contactInformation: "", type: '1' }]
      this.wechat = [{ contactInformation: "", type: '4' }]
      this.zuoji = [{ contactInformation: "", type: '2' }]
    },
    //转移
    exportChange(type, e) {
      this.exportIdArr = [];
      if (type == 0) {
        this.exportIdArr[0] = e;
        this.exportVisible = true;
      } else {
        if (this.selectedRowKeys.length > 0) {
          this.exportVisible = true;

          this.selectedRowKeys.forEach(item => {
            this.exportIdArr.push(this.dataSource[item].id)
          })
        }
      }


    },
    phoneChange(e, index) {
      this.phone[index].type = ''
      this.phone[index].type = e.key;
    },
    weChatChange(e, index) {
      this.wechat[index].type = ''
      this.wechat[index].type = e.key;
    },
    zuojiChange(e, index) {
      this.zuoji[index].type = ''
      this.zuoji[index].type = e.key;
    },
    exportHandle() {
      if (this.exportIdArr.length == 0) {
        this.$message.error('请选择客户');
        return false;
      }
      if (!this.queryData.customerManagerIdList) {
        this.$message.error('请选择转移人');
        return false;
      }
      let params = []
      this.exportIdArr.forEach(item => {
        params.push({ customerId: item, staffIdList: this.queryData.customerManagerIdList })
      })
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/shift', 'post', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('转移成功！')
          this.exportVisible = false;
          this.queryData.customerManagerIdList = [];
          this.getData()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    checkEmptyProperty(obj) {
      return Object.values(obj).every(val => val !== '');
    },
    // 保存
    handleOk(e) {
      e.preventDefault()
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.phone[0].contactInformation == "" && this.wechat[0].contactInformation == "" && this.zuoji[0].contactInformation == "") {
            this.$message.error('请补全联系方式')
            return false;
          }

          // 校验福利金额
          let fare = true;
          this.customerWelfareList.forEach((obj) => {
            for (const key in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const value = obj[key];
                if (!value && value !== 0) {
                  fare = false;
                }
              }
            }
          });
          if (!fare) {
            this.$message.error('福利金额不可为空！');
            return false;
          }
          // 过滤空联系号码框
          let arr = this.phone.concat(this.wechat, this.zuoji);
          let mationList = arr.filter(obj => {
            for (let key in obj) {
              if (obj[key] === '') {
                return false; // 
              }
            }
            return true;
          });


          // 过滤空地址
          let sfaContactAddressList = this.sfaContactAddressList
          sfaContactAddressList = this.sfaContactAddressList.map(obj => {
            for (let key in obj) {
              if (obj[key] === '') {
                delete obj[key];
              }
            }
            return obj;
          }).filter(obj => Object.values(obj).some(value => value !== ''));
          let customerAddressList = this.customerAddressList;
          customerAddressList = this.customerAddressList.map(obj => {
            for (let key in obj) {
              if (obj[key] === '') {
                delete obj[key];
              }
            }
            return obj;
          }).filter(obj => Object.values(obj).some(value => value !== ''));
          let params = {
            "name": this.userInfo.gsname,
            "type": 1,
            "scale": this.userInfo.scale,
            "enterpriseType": this.userInfo.enterpriseType,
            "remarks": this.userInfo.remarks,
            "abbreviation": this.userInfo.abbreviation,
            "source": this.userInfo.source,
            "budgetAmount": this.userInfo.budgetAmount,
            "customerManagerIdList": this.userInfo.customerManagerIdList,
            "customerWelfareList": this.customerWelfareList,
            "customerAddressList": customerAddressList,
            "sfaContactsList": [{
              "name": this.userInfo.name,
              "role": this.userInfo.role,
              "sex": this.userInfo.sex,
              "birthday": this.userInfo.birthday,
              "sfaContactInformationList": mationList,
              "sfaContactAddressList": sfaContactAddressList
            }
            ]
          }
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/check', 'post', params).then(res => {
            if (res.data.data) {
              this.$message.warning(res.data.data)
              return false;
            }
            this.$message.loading();
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/save', 'post', params).then(res => {
              this.$message.destroy()
              if (res.data.code == 200) {
                this.$message.success('添加成功！');
                this.visible = false;
                this.getData();
                this.reload();
              } else {
                this.$message.error(res.data.message);
              }
            })
          })
        } else {
          return false;
        }
      });

    },
    // 添加地址
    addAddress(type, i) {
      if (type == 3 || type == 4) {
        if (type == 3) {
          this.customerAddressList.push({})
        } else {
          this.customerAddressList.splice(i, 1)
        }
        return false;
      }
      if (type == 0) {
        this.sfaContactAddressList.push({})
      } else {
        this.sfaContactAddressList.splice(i, 1)
      }
    },
    getCity() {
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/0', 'get').then(res => {
        if (res.data.code == 200) {
          this.prov = res.data.data;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    provChange(id, index) {
      const selProv = this.prov.find(item => item.id === id);
      this.sfaContactAddressList[index].provinceCode = selProv.id;
      this.sfaContactAddressList[index].provinceName = selProv.name;
      this.sfaContactAddressList[index].cityCode = '';
      this.sfaContactAddressList[index].cityName = '';
      this.sfaContactAddressList[index].countyCode = '';
      this.sfaContactAddressList[index].countyName = '';
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.city = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    cityChange(id, index) {
      const selProv = this.city.find(item => item.id === id);
      this.sfaContactAddressList[index].cityCode = selProv.id;
      this.sfaContactAddressList[index].cityName = selProv.name;
      this.sfaContactAddressList[index].countyCode = '';
      this.sfaContactAddressList[index].countyName = '';
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.area = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    areaChange(id, index) {
      const selProv = this.area.find(item => item.id === id);
      this.sfaContactAddressList[index].countyCode = selProv.id;
      this.sfaContactAddressList[index].countyName = selProv.name;
    },

    provChange1(id, index) {
      const selProv = this.prov.find(item => item.id === id);
      this.customerAddressList[index].provinceCode = selProv.id;
      this.customerAddressList[index].provinceName = selProv.name;
      this.customerAddressList[index].cityCode = '';
      this.customerAddressList[index].cityName = '';
      this.customerAddressList[index].countyCode = '';
      this.customerAddressList[index].countyName = '';
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.city = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    cityChange1(id, index) {
      const selProv = this.city.find(item => item.id === id);
      this.customerAddressList[index].cityCode = selProv.id;
      this.customerAddressList[index].cityName = selProv.name;
      this.customerAddressList[index].countyCode = '';
      this.customerAddressList[index].countyName = '';
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.area = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    areaChange1(id, index) {
      const selProv = this.area.find(item => item.id === id);
      this.customerAddressList[index].countyCode = selProv.id;
      this.customerAddressList[index].countyName = selProv.name;
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    // 编辑
    editChange(id) {
      this.$router.push({
        path: '/sfa_customer/addForm',
        query: { id: id }
      });
    },
    details(id) {
      this.$router.push({
        path: '/sfa_customer/Contact',
        query: { id: id }
      });
    },
    progDeal(id) {
      this.$router.push({
        path: '/sfa_customer/InvoiceList',
        query: { id: id }
      });
    },
    // 选择日期
    dateChange(date, dateString) {
      this.queryData.startTime = dateString[0].toString();
      this.queryData.endTime = dateString[1].toString();
    },
    resetQuery() {
      this.queryData = {
        page: 0,
        size: 10,
        name: '',
        phone: '',
        address: '',
        dateMax: '',
        dateMin: '',
        startTime: '',
        endTime: '',
        remarks: ''
      }
      this.getData()
    },
    birthdayChange(date, dateString) {
      this.userInfo.birthday = dateString
    },
    createChange(date, dateString) {
      this.userInfo.createTime = dateString
    },
    addIpt(i) {
      if (i == 0) {
        this.phone.push({ contactInformation: "", type: "1" });
      }
      if (i == 1) {
        this.wechat.push({ contactInformation: "", type: "4" });
      }
      if (i == 2) {
        this.zuoji.push({ contactInformation: "", type: "2" });
      }
    },
    delIpt(type, i) {
      if (type == 0) {
        this.phone.splice(i, 1)
      } else if (type == 1) {
        this.wechat.splice(i, 1)
      } else {
        this.zuoji.splice(i, 1)
      }
    },
    // 获取列表 
    getData() {
      this.tabLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'post', this.queryData).then(res => {
        let data = res.data.data;
        this.tabLoading = false;
        if (res.data.code == 200) {
          this.dataSource = data.data;
          this.queryData.page = data.page;
          this.queryData.size = data.size;
          this.total = data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })

      // 查询当日新增
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/countCustomer', 'post', this.queryData).then(res => {
        let data = res.data.data;
        this.countCustomerToday = data.toDayNumber;
        this.totalNumber = data.totalNumber;
      })

    },
    // 是否确认删除
    delConfirm(e) {
      this.$message.loading();
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/' + e.id, 'delete').then(res => {
        this.$message.destroy();
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.getData()
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}



.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}

::v-deep .ant-card-body {
  padding-top: 0;
}



.top-text p {
  height: 10px;
}

.top-text div {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-right: 20px;
  padding: 2px 20px;
  box-sizing: border-box;
}
</style>
